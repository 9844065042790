import Head from "next/head";
import classes from "../../styles/Rules.module.css";
import MetaTags from "../../components/MetaTags";

// just enable wrapping
/* eslint-disable max-len */
export const rulesConsts = {
  info: "Пожалуйста, прочтите все правила приложения, это не займет много времени, но поможет лучше понимать как устроено наше сообщество.",
  rules: [
    {
      rule: "Запрещен спам. Многократно создавать похожие публикации или сообщения в чате.",
      title: "Спам",
      correct: [
        "Пользователь написал \"Привет. Как дела? Что делаете?\" тремя сообщениями.",
        "Пользователь опубликовал 5 разных мемов.",
      ],
      incorrect: [
        "Пользователь 5 раз подряд написал \"Привет\".",
        "Пользователь создал 3 публикации подряд в каждой из которых арт на игрового персонажа.",
      ],
    },
    {
      rule: "Запрещено оскорблять других пользователей и использовать мат.",
      title: "Оскорбления/Грубое поведение",
      correct: [
        "Пользователь в шутку использовал несерьёзное ругательство.",
        "Пользователь вежливо критикует другого.",
        "Пользователь использовал мат, но скрыл часть слова.",
      ],
      incorrect: [
        "Пользователь напрямую использовал мат.",
        "Пользователь оскорбляет или высмеивает другого (даже без мата).",
        "Пользователь оскорбляет другого используя скрытый мат.",
      ],
    },
    {
      rule: "Запрещен саботаж. Целенаправленно мешать общению в чате, вызывать проблемы с приложением.",
      title: "Вредительство/Саботаж",
      correct: ["Пользователь случайно воспроизвел баг из-за которого у других могут возникнуть проблемы."],
      incorrect: [
        "Пользователь очень часто пишет сообщения, мешая общению других.",
        "Пользователь намерено делает большое количество публикаций, чтобы скрыть чужие.",
        "Пользователь узнал о баге который приводит к проблемам у других и начал активно его использовать.",
      ],
    },
    {
      rule: "Запрещено публиковать порнографию, пиратский/аморальный/незаконный/шок контент.",
      title: "Запрещенный контент",
      correct: [
        "Публикация людей/персонажей в купальниках и т.п.",
        "Скриншоты сделанные на пиратской версии программы/игры.",
      ],
      incorrect: [
        "Публикация на которой персонаж частично или полностью обнажен.",
        "Публикация на которой персонаж не обнажен, но находится в вызывающей позе.",
        "Порнографический рассказ или текст.",
        "Ссылка которая ведет на запрещенный контент.",
      ],
    },
    {
      rule: "Запрещен оффтоп, бессмысленные и низкокачественные публикации. Публикации не имеющие отношения к фэндому, бессмысленные опросы.",
      title: "Оффтоп/Бессмысленные публикации",
      correct: [
        "Опрос с полноценным описанием его цели.",
        "Публикации о пользователях фэндома, хоть они и не относятся к теме фэндома.",
      ],
      incorrect: [
        "Опрос делать ли пост по какой-то теме без внятного описания.",
        "Изображения отвратительного качества.",
        "Пост текст которого без причины окрашен в цвет/цвета сильно отличающийся от стандартного.",
        "Пост-Анонс другого поста: \"Завтра я выложу прохождение нового уровня, ждите\", но не распространяется на рубрики, например если по каким-то причинам ожидаемый пост задерживается, его можно анонсировать.",
        "Скриншоты сделанные на телефон, качественные посты, но бессмысленные (с бесполезным контентом).",
      ],
    },
    {
      rule: "Спойлеры сюжета обязательно должны быть помещены под карточку \"спойлер\", а в заголовке карточки должно быть предупреждение.",
      title: "Cпойлер",
      correct: [
        "Пользователь предупредил что раскрывает часть сюжета и скрыл это под спойлер при возможности.",
      ],
      incorrect: [
        "Пользователь раскрыл часть сюжета без предупреждения.",
      ],
    },
    {
      rule: "Запрещено использовать функции приложения во вред. Например делать пост мультиязычным, в случае если он может быть не понятен на других языках.",
      title: "Использование функций не по назначению",
      correct: [
        "Пользователь сделал мультиязычным пост в котором присутствует изображение с не английским текстом, но этот текст не важен для понимания поста.",
        "Пользователь сделал мультиязычным пост в котором присутствует английский текст, но его всего пара предложений.",
      ],
      incorrect: [
        "Пользователь сделал мультиязычным пост в котором важная часть текста не на английском языке.",
        "Пользователь пометил пост с артом персонажа как важный.",
        "Пользователь многократно жалуется на публикации которые не нарушают правил.",
        "Пользователь сделал мультиязычным пост в котором стена английского текста.",
      ],
    },
    {
      rule: "Запрещено использовать дополнительные аккаунты для обхода блокировки основного аккаунта.",
      title: "Обход блокировки",
      correct: ["Пользователь использует дополнительные аккаунты для своих целей не нарушая других правил."],
      incorrect: ["Пользователь после блокировки в чате, вернулся в него на другом аккаунте и продолжил общение."],
    },
    {
      rule: "В RP фэндомах могут быть установлены дополнительные правила.\nОбязательные требования:\n1. Фэндом должен быть закрыт.\n2. Правила должны быть закреплены в ленте фэндома.\n3. Правила не могут противоречить правилам Campfire.\n4. Блокировать пользователя можно только в этом фэндоме.\n5. Названии фэндома обязательно должно начинаться с \"RP\".",
      title: "RP",
      correct: ["Пользователь нарушил правила фэндома, которые были заданы не по требованиям к таким правилам."],
      incorrect: ["Пользователь вышел из роли, хотя это запрещено правилами фэндома."],
    },
  ],
};

export default function Rules() {
  return <main className="container">
    <Head>
      <title>Общие правила Campfire</title>
      <MetaTags
        title="Общие правила Campfire"
        description={rulesConsts.info}
        url="https://campfire.moe/app/rules"
      />
    </Head>
    <header className={classes.rulesHeader}>
      {rulesConsts.info}
    </header>
    {rulesConsts.rules.map((rule, idx) => <section key={idx} className={classes.rule}>
      <h2>{idx + 1}. {rule.title}</h2>
      <p className={classes.ruleContent}>{rule.rule}</p>
      <div className={classes.correct}>
        Разрешено: <ul>{
          rule.correct.map((example, idx) => <li key={idx}>{example}</li>)
        }</ul>
      </div>
      <div className={classes.incorrect}>
        Запрещено: <ul>{
          rule.incorrect.map((example, idx) => <li key={idx}>{example}</li>)
        }</ul>
      </div>
    </section>)}
  </main>;
}
